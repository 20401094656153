import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';

export default  {
    data() {
		return {
			reportName: '',
			fromDate: new Date(new Date().setDate((new Date()).getDate() - 30)),
			toDate: new Date(),
			productStatus: null,
			isResultReady: false,
			branchId: Helper.getBranchSellerId(),
			activePage: 1,
			totalPage: 0,
			resultList: []
		}
	},
	mounted() {
		this.getReport();
	},
	methods: {
		async getReport() {
			this.isResultReady = false;
			this.resultList = [];
			
            const result = await this.selectReportApi();

			this.isResultReady = true;
			this.totalPage = result.data.totalPage;
			this.resultList = result.data.resultList;
		},
		changeFilterReportTable(filter) {
			this.fromDate = filter.fromDate;
			this.toDate = filter.toDate;
			this.productStatus = filter.productStatus;
			this.activePage = 1;

			this.getReport();
		},
		changePage(page) {
			this.activePage = page;
			this.getReport();
		},
		async selectReportApi() {
			let result = null;

			switch (this.reportName) {
				case 'sale':
					result = await SellerService.getReportSale(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;

				case 'product-remain':
					result = await SellerService.getReportProductRemain(this.activePage, this.fromDate, this.toDate, this.branchId, this.productStatus);
					break;

				case 'promotion':
					result = await SellerService.getReportPromotion(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;

				case 'product-promotion':
					result = await SellerService.getReportProductPromotion(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;

				case 'product-movement':
					result = await SellerService.getReportProductMovement(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;

				case 'system':
					result = await SellerService.getReportSystem(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;

				case 'customer':
					result = await SellerService.getReportCustomer(this.activePage, this.fromDate, this.toDate, this.branchId);
					break;
			}

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			}

			return result;
		},
		changeBranch(branchId) {
			this.activePage = 1;
			this.branchId = branchId;
			this.getReport();
		}
	}
}