<template>
	<div class="vue-dragscoll" v-dragscroll.x @dragscrollstart="dragStart()" @dragscrollend="dragEnd()" @click.capture="dragClick($event)">
		<table>
			<thead>
				<tr>
					<th v-for="(item, index) in data.head" :key="index" :class="columnAlignment(item)">{{ item.text }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, rowIndex) in data.body" :key="rowIndex">
					<td v-for="(column, columnIndex) in row" :key="columnIndex" :class="columnAlignment(data.head[columnIndex])">{{ column }}</td>
				</tr>
			</tbody>
			<tfoot v-if="data.footer.length > 0">
				<tr>
					<td v-for="(item, index) in data.footer" :key="index" :class="columnAlignment(data.head[index])">{{ item }}</td>
				</tr>
			</tfoot>
		</table>
	</div>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';

export default {
	mixins: [ MixinDragScroll ],
	props: {
		data: Object
	},
	methods: {
		columnAlignment(column) {
			if (Object.prototype.hasOwnProperty.call(column, 'align')) {
				return 'text' + '-' + column.align;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

table {
	width: 100%;
	font-size: 13px;
	line-height: 15px;

	thead {
		th {
			background: #fbac53;
			padding: 13px 10px;
			min-width: 100px;
		}
	}

	tbody {
		td {
			border-bottom: 1px solid #999;
			padding: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 400px;
		}
	}

	tfoot {
		td {
			padding: 10px;
			font-size: 17px;
			font-weight: bold;
			border-bottom: 1px solid #999;
			background: #f5f5f5;

			@media only screen and (max-width: $screenSmall) {
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
}
</style>