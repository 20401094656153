<template>
	<div class="form">
		<div class="date">
			<span class="d-none d-sm-block">ตั้งแต่วันที่</span>

			<div class="input-date-icon">
				<datepicker
					v-model="fromDate"
					@input="changeFilterReportTable()"
					:input-class="[ 'input-date', 'square' ]"
					:language="calendarTH"
					:full-month-name="true"
					calendar-class="datepicker-box"
					placeholder="ตั้งแต่วันที่"
					format="dd/MM/yyyy">
				</datepicker>
				<b-icon icon="calendar"></b-icon>
			</div>

			<span>ถึง</span>

			<div class="input-date-icon">
				<datepicker
					v-model="toDate"
					@input="changeFilterReportTable()"
					:input-class="[ 'input-date', 'square' ]"
					:language="calendarTH"
					:full-month-name="true"
					calendar-class="datepicker-box"
					placeholder="ถึงวันที่"
					format="dd/MM/yyyy">
				</datepicker>
				<b-icon icon="calendar"></b-icon>
			</div>

			<div class="input-option" v-if="isShowFilterStatus">
				<dropdown
					v-model="productStatus"
					placeholder="-- สถานะ --"
					:options="optionStatus"
					:classes="{ input: ['square'] }"
					@change="changeFilterReportTable()">
				</dropdown>
			</div>
		</div>
		<div class="operation">
			<button @click="exportExcel()">
				<b-icon icon="download"></b-icon>
			</button>
		</div>
	</div>
</template>

<script>
import { th } from 'vuejs-datepicker/dist/locale';
import OptionStockStatus from '@/services/staticOption/stockStatus';
import SellerService from '@/services/sellerService';
import Helper from '@/services/helper';

export default {
	data() {
		return {
			optionStatus: OptionStockStatus.option,
			productStatus: null,
			calendarTH: th,
			fromDate: new Date(new Date().setDate((new Date()).getDate() - 30)),
			toDate: new Date()
		}
	},
	props: {
		reportName: {
            type: String
        },
		branchId: {
            type: Number
        },
		isShowFilterStatus: {
            type: Boolean,
			default: false
        }
	},
	methods: {
		async changeFilterReportTable() {
			setTimeout(() => {
				this.$emit('changeFilterReportTable', { fromDate: this.fromDate, toDate: this.toDate, productStatus: this.productStatus });
			});
		},
		async exportExcel() {
            let loader = this.$modalLoader.render();
			const param = {
				report: this.reportName,
				fromDate: this.fromDate,
				toDate: this.toDate,
				branchId: this.branchId,
				productStatus: this.productStatus
			};
			
			const result = await SellerService.exportReport(param);
			loader.hide();

			if (result.data?.success) {
				Helper.fileDownloadByApi(result.data?.file);
			} else {
				this.$swal(Helper.warningAlert('ไม่สามารถดาวน์โหลดไฟล์ Excel ได้', result.data?.message));
			}
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';
.form {
	display: flex;
	justify-content: space-between;

	.date {
		display: flex;
		align-items: center;
		font-size: 13px;

		@media only screen and (max-width: $screenExtraSmall) {
			font-size: 12px;
		}

		.input-date-icon {
			width: 200px;

			@media only screen and (max-width: $screenSmall) {
				width: 140px;
			}

			@media only screen and (max-width: $screenExtraSmall) {
				width: 120px;
			}

			.bi {
				top: 28%;

				@media only screen and (max-width: $screenLarge) {
					top: 33%;
				}

				@media only screen and (max-width: $screenExtraSmall) {
					top: 34%;
				}
			}
		}

		span {
			margin: 0 10px;

			&:first-child {
				margin-left: 0; 
			}
		}
	}

	.input-option {
		width: 200px;
		margin-bottom: 0;
		margin-left: 10px;

		@media only screen and (max-width: $screenSmall) {
			width: 140px;
		}

		@media only screen and (max-width: $screenExtraSmall) {
			width: 120px;
		}
	}

	.operation {
		button {
			font-size: 18px;
			background: #f2f2f2;
			color: #555;
			cursor: pointer;
			border: 1px solid #ccc;

			&:hover {
				opacity: 0.5;
			}		
		}
	}
}

::v-deep .input-date-icon {
    .input-date {
        padding: 8px 15px;
		border: none;
		background: #f2f2f2;
    }
}

::v-deep .input-option {
	input {
		padding: 8px 15px;
		border: none;
		background: #f2f2f2;
	}
}
</style>